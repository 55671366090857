@import "@styles/common";

$checkmark-bg: linear-gradient(62.22deg, #e4611e 17.77%, #ff792b 94.43%);
$checkmark-size: 20px;
$disabled-checkbox-color: $border-color;

.checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &__block {
    font-size: $font-size-hint;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;

    @include flex;
  }

  &__input {
    display: none;
  }

  &__checkmark {
    position: relative;
    display: inline-block;
    width: $checkmark-size;
    height: $checkmark-size;
    margin-right: 10px;
    border: 1px solid $border-color;
    border-radius: 5px;
  }

  &__checkmark::after {
    position: absolute;
    top: 3px;
    left: 6px;
    box-sizing: content-box;
    display: none;
    width: 3px;
    height: 7px;
    content: "";
    border: solid #ffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  &--disabled {
    cursor: not-allowed;

    .checkbox__checkmark {
      cursor: not-allowed;
      background-color: $light-gray;
    }

    .checkbox__heading {
      color: $disabled-checkbox-color;
      cursor: not-allowed;
    }
  }

  &--checked {
    .checkbox__checkmark:not(.disabled) {
      background: $checkmark-bg;
      border-color: #ef5a50;
    }

    .checkbox__checkmark::after {
      box-sizing: content-box;
      display: block;
    }

    .checkbox__checkmark--disabled {
      cursor: not-allowed;
      background: $gray;
      background-color: $gray;
      border: 1px solid $gray;
    }
  }
}
