@import "@styles/common";
@import "@styles/z-index";

.autocomplete {
  position: relative;

  &__variants {
    position: absolute;
    top: 72px;
    z-index: map-get($z-indexes, high1);
    width: 100%;
    background-color: #ffff;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 7%);
  }

  &__varian:hover {
    background-color: #ddeaff;
  }

  &__variant {
    padding: 10px;
    cursor: pointer;

    &--no-matches-found {
      color: $gray;
      text-align: center;
    }
  }
}
