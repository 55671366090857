@mixin backgroundImage($image) {
  background-image: url($image);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin tablet($tablet-width, $desktop-width) {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop($desktop-width) {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile($mobile-width) {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin truncate-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin truncate-lines($linesCount: 1) {
  -webkit-box-orient: vertical;
  display: box;
  -webkit-line-clamp: $linesCount;
  overflow: hidden;
}

@mixin flex($justify-content: center, $align-items: center, $flex-direction: row) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin font(
  $font-size,
  $font-weight: normal,
  $line-height: 20px,
  $color: $black,
  $font-style: normal,
  $font-family: $global-font-family
) {
  font-family: $font-family;
  font-size: $font-size;
  font-style: $font-style;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
}

@mixin customScrollbar() {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color: $gray, $alpha: 20%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 30px;
  }
}

@mixin input() {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 14px;
  color: #292929;
  background-color: #ffff;
  border: 1px solid $border-color;
  border-radius: 8px;
  outline: none;
}
