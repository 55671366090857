@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.search {
  position: relative;
  min-width: 200px;
  font-family: $global-font-family;
  font-size: 14px;
  background-color: #0000;
  border-radius: 8px;

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 13px 40px 12px 35px;
    border: 1px solid #0000;
    border-radius: 8px;
    outline: none;
  }

  &__control {
    @include flex(flex-start);

    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    padding: 10px;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &__icon {
    position: absolute;
    bottom: 10px;
    width: 20px;
    height: 20px;
    padding: 0 20px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
