@import "./z-index";

$global-font-family: "Quicksand";
$global-second-font-family: serif;
$light-gray: #f8f7f7;
$light-blue: #ebeff8;
$main-color: #292929;
$main-background-color: $light-gray;
$main-background-dark: #2e3548;
$red: #eb5769;
$orange: #e4611e;
$green: #7cbc72;
$main-border-radius: 10px;
$text-color: $main-color;
$font-size-huge: 48px;
$font-size-title: 24px;
$font-size-subtitle: 18px;
$font-size-normal: 16px;
$font-size-small: 12px;
$font-size-hint: 14px;
$gray: #9e9e9e;
$blue: #6892d1;
$accent-color: #e4611e;
$accent-color2: $orange;
$accent-gradient: linear-gradient(62.22deg, $accent-color 17.77%, #ff792b 94.43%);
$import-gradient: linear-gradient(62.22deg, #0a995d 17.77%, #16ab6d 94.43%);
$disabled-button-color: $gray;
$active-color: $green;
$inactive-color: $gray;
$invited-color: $blue;
$deactivated-color: $red;
$draft-color: $blue;
$border-color: #ddd;
$field-bg-disabled: #f3f3f3;
$notification-error: $red;
$error-field-message: $red;
$notification-error: $red;
$notification-success: #7cbc72;
$notification-shadow: 0 18px 35px rgb(0 0 0 / 20%);
$notification-bg: #ffff;
$sidebar-width: 205px;
$table-header-height: 40px;
