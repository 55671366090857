@import "@styles/common";

.custom-datepicker {
  @include flex(flex-start, stretch, column);

  input {
    height: 100%;
    padding: 0;
    padding-left: 11px;
  }

  .custom-datepicker-wrapper {
    width: 100%;
    font-family: $global-font-family;

    &.rs-picker-disabled {
      opacity: 1;
    }

    .rs-picker-toggle[disabled] {
      background-color: #f3f3f3 !important;

      .rs-picker-toggle-value {
        color: $gray;
      }
    }

    .rs-picker-toggle-value {
      font-size: $font-size-hint;
      color: $main-color;
    }
  }
}

.rs-picker-date-menu {
  .rs-calendar-time-dropdown-column ul {
    @include customScrollbar();
  }

  .rs-calendar-month-dropdown-row-wrapper {
    @include customScrollbar();
  }
}

.rs-picker-menu {
  z-index: 100;
  margin-top: 0.5em;
}

.time-picker-switcher {
  display: flex;
  width: 180px;

  .time-picker-switcher-hours-selector {
    width: 50%;

    .input-wrapper {
      margin-bottom: 0;
    }
  }

  .time-picker-switcher-meridian-selector {
    width: 50%;
    padding-left: 1em;
  }

  .rs-icon {
    display: none;
  }

  .rs-picker-toggle-value {
    padding: 0;
    text-align: center !important;
  }

  .rs-picker-toggle {
    padding: 0.65em !important;
  }

  .custom-datepicker input {
    padding: 0;
    text-align: center;
  }

  .rs-btn {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    color: #292929;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 8px;
    outline: none;
  }
}
