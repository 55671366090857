@import "@assets/styles/variables";

.textarea {
  display: flex;
  flex-direction: column;

  textarea {
    padding: 10px;
    font-family: $global-font-family;
    font-style: normal;
    font-weight: 400;
    color: $text-color;
    resize: none;
    border: 1px solid $border-color;
    border-radius: 8px;
  }

  textarea:hover,
  textarea:active,
  textarea:focus {
    outline: none;
  }

  textarea::placeholder {
    font-size: 14px;
    color: rgb(24 25 32 / 40%);
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    padding-left: 10px;
    font-size: 12px;
    line-height: 30px;
    color: $gray;
  }

  &__counter {
    padding-right: 10px;
    font-size: 12px;
    line-height: 30px;
    color: $gray;
  }
}
