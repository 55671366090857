@import "./variables";

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .block {
    width: 100%;
  }

  .block:first-child {
    margin-right: 10px;
  }

  .block:last-child {
    width: 100%;
    margin-left: 10px;
  }
}

.form-subtitle {
  margin: 20px 0;
  font-size: $font-size-subtitle;
}

.form-additional-status {
  padding: 20px 0;

  .entity-status {
    & > div:first-child {
      margin-right: 20px;
    }

    @include flex;
  }

  .entity-update-status {
    font-size: $font-size-normal;
    font-weight: 500;
    color: $active-color;
    cursor: pointer;

    &.deactivate {
      color: $red;
    }

    &.resend {
      color: $invited-color;
    }
  }

  .entity-invited-status-button {
    .resend {
      margin-left: 20px;
    }

    @include flex;
  }

  @include flex(space-between);
}

.form-additional-label {
  margin-right: 5px;
  font-size: $font-size-small;
  color: $gray;
}

.form-check-box-row {
  margin-top: 10px;
}

.form-subtitle-wrapper {
  font-family: $global-font-family;

  .form-subtitle-right-wrapper {
    @include flex(flex-start);
  }

  .form-subtitle-title {
    font-size: $font-size-subtitle;
    font-weight: 400;
    color: $invited-color;
  }

  .form-subtitle-add,
  .form-subtitle-clear {
    font-size: 16px;
    font-weight: 500;
    color: $accent-color2;
    cursor: pointer;

    &.disabled {
      color: $gray;
      cursor: not-allowed;
    }
  }

  .form-subtitle-clear {
    margin-right: 25px;
    color: $gray;
  }

  @include flex(space-between);
}
