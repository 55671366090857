/* Quicksand Font Styles */

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 900;
  src: url("Quicksand-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 300;
  src: url("Quicksand-Light.ttf") format("truetype");
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  src: url("Quicksand-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  src: url("Quicksand-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  src: url("Quicksand-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: local("Rubik"), local("Rubik-Regular"), url("Rubik-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  src: local("Rubik-Bold"), url("Rubik-Bold.woff2") format("woff2");
}
