@import "@assets/styles//common";

.multi-autocomplete {
  &__values {
    flex-wrap: wrap;
    margin-top: 10px;

    @include flex(flex-start);
  }

  &__value-close {
    margin-left: 10px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &__value {
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: $font-size-hint;
    background: rgba($color: #ddd, $alpha: 20%);
    border-radius: 10px;

    &--is-clickable {
      cursor: pointer;
    }

    &--is-locked {
      color: $gray;

      .lock-icon {
        width: 25px;
        height: 20px;
      }
    }

    @include flex(flex-start);
  }
}
